import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import MissingCmsValueNotif from '../../elements/_custom/MissingCmsValueNotif';

import Image from "../../shared/Image/Image";
import Embed from '../../shared/Embed/Embed';
import HtmlContent from '../../shared/HtmlContent/HtmlContent';
import Modal from '../../shared/Modal/Modal';

import Linkedin from '../../../assets/img/social/linkedin.svg'
import Github from '../../../assets/img/social/github.svg'
import Edge from '../../shared/Edge/Edge';

const ProjectPhaseView = ({ data: propData }) => {
  const modalElement = useRef(null)

  const [currentTeamMemberViewing, setCurrentTeamMemberViewing] = useState(null)
  /**
   * Open the modal in the child component
   * @param  {Object} event
   * @param  {Object} person

    * @return {Void}
   */
  const handleOpenModal = (event) => {
    event.preventDefault()
    modalElement.current.showModal()
  }

  const data = propData.prismicProjectPhase.data;

  const headline = data.headline.text || <MissingCmsValueNotif missingKey="headline" />;
  const headlineImage = data.main_logo ? data.main_logo : null;
  const mainVideo = Object.keys(data.main_video).length > 0 ? data.main_video : null;
  const shortTagLine = data.short_tag_line;

  const descriptionImage = data.description_image ? data.description_image : null;
  const descriptionHtml = data.description_text.html;

  const theTeam = data.the_team;

  const technologiesImage = data.technologies_image;
  const technologiesDescription = data.technologies_description.html || null;

  const faqs = data.faq;
  const challengesFaced = data.challenges_faced.html;

  const doesHeadlineImageExist = headlineImage.url !== null;

  let teamName = '';
  let projectName = '';

  if (headline.length > 0) { // This will break if not checked
    /*
      We're gonna hope people follow the content type placeholder a little here,
      as we want to make the 'present' bit look nicer than when it's in the
      normal headline.
    */
    teamName = headline.split(' present ')[0];
    projectName = headline.split(' present ')[1];
  } else {
    teamName = 'Team Name';
    projectName = 'Project Name';
  }
  return (
    <>
      <div className='lg:px-0'>
        <div className="c-hero c-hero__normal_bg c-hero__remove_edge_margin_fully">
          <div className={`
              grid max-w-7xl w-full mx-auto
              ${doesHeadlineImageExist ? 'grid-cols-1 md:grid-cols-2 z-auto justify-between' : 'grid-cols-1'}
            `}>
            <h1 className={`
                 mb-4 ml-4 lg:ml-0
                ${!doesHeadlineImageExist ? 'text-center' : 'mb-12 text-center md:text-left'}
              `}>
              <div className='mb-4'>{teamName}</div>
              <strong className='block text-2xl md:text-4xl '>present</strong>
              <span className='border-b-4 border-primary'>{projectName}</span>
            </h1>
            {doesHeadlineImageExist ? (
              <Image fallbackAlt={headlineImage.alt || "Headline image"} {...headlineImage} />
            ) : null}
          </div>
        </div>

        {mainVideo && (
          <div className="c-project-phase-video w-1/2 mx-auto mb-12 lg:-mt-64 z-aboveAll">
            <Embed
              className={"lg:z-highest"}
              embed={mainVideo}
              title={mainVideo.title}
            />
          </div>
        )}

        <section className='z-50'>
          <Edge
            location='top'
            direction='left'
            outerClassName='text-white'
            innerClassName='text-primary'
          />
          <div className="text-center text-white mx-auto bg-primary py-24 lg:py-32 px-12 md:px-24">
            <h2 className="c-hero__title inline-block mb-12 font-bold pb-2">
              {shortTagLine.text}
            </h2>
            <div className={`
                grid gap-x-10 justify-items-end w-full
              z-highest
                ${descriptionImage.url !== null ? 'md:grid-cols-2' : 'md:grid-cols-1'}
              `}>
              {descriptionHtml ? <HtmlContent html={descriptionHtml} className={`c-prose mb-8 md:mb-0 md:text-left tracking-wide leading-relaxed px-6 ${descriptionImage.url !== null ? 'md:text-left' : 'md:text-center'}`} /> : <MissingCmsValueNotif missingKey="description_text" />}
              {descriptionImage.url !== null ? <Image fallbackAlt={descriptionImage.alt || "Description image"} {...descriptionImage} /> : <MissingCmsValueNotif missingKey="description_image" />}
            </div>
          </div>
          <Edge
            location='bottom'
            direction='left'
            outerClassName='text-white'
            innerClassName='text-primary'
          />
        </section>

        {theTeam.length > 0 ? (<section className='my-24'>
          <div className="text-center bg-white">
            <h2 className="c-hero__title inline-block mb-12 font-bold pb-2">
              The Team
            </h2>
            <ul className="grid sm:grid-cols-2 md:grid-cols-4 max-w-6xl mx-auto">
              {
                theTeam.map((teamMember) => (
                  <li
                    className='md:h-64 md:w-64 flex flex-col items-center justify-center'
                    onClick={(event) => {
                      setCurrentTeamMemberViewing(teamMember)
                      handleOpenModal(event)
                    }}
                  >
                    <Image
                      fallbackAlt={teamMember.team_member_image.alt || 'Team member image'}
                      dimensions={{ width: 64, height: 64 }}
                      className="mb-4 object-cover"
                      {...teamMember.team_member_image}
                    />
                    <p className='text-center font-medium text-xl mb-4'>
                      {teamMember.team_member_name.text}
                    </p>
                  </li>
                ))
              }
            </ul>
          </div>
        </section>) : null}

        {Object.keys(technologiesImage).length > 1 && technologiesDescription ? (
          <section className='my-24'>
            <Edge
              location='top'
              direction='left'
              outerClassName='text-white'
              innerClassName='text-offWhite'
            />
            <div className="text-center py-12 bg-offWhite">
              <h2 className="c-hero__title inline-block mb-12 font-bold pb-2">
                Technologies
              </h2>
              <div className='grid md:grid-cols-2 max-w-6xl mx-auto mb-10 gap-x-10'>
                {Object.keys(technologiesImage).length > 1 ? <Image className={"mb-6 md:mb-0"} fallbackAlt={technologiesImage.alt || "Technologies section image"} {...technologiesImage} /> : <MissingCmsValueNotif missingKey="technologies_image" />}
                {technologiesDescription ? <HtmlContent html={technologiesDescription} className="c-prose sm:text-center md:text-left tracking-wide leading-relaxed px-6" /> : <MissingCmsValueNotif missingKey="technologies_description" />}
              </div>
            </div>
            <Edge
              location='bottom'
              direction='right'
              outerClassName='text-white'
              innerClassName='text-offWhite'
            />
          </section>
        ) : null}

        {challengesFaced.length > 0 ? (
          <section className='my-24'>
            <div className="text-center max-w-6xl mx-auto pb-12">
              <h2 className="c-hero__title inline-block mb-12 font-bold pb-2">
                Challenges Faced
              </h2>
              {challengesFaced.length > 0 ? <HtmlContent html={challengesFaced} className='c-prose px-6 tracking-wide leading-relaxed' /> : <MissingCmsValueNotif missingKey="challenges_faced" />}
            </div>
          </section>
        ) : null}

        {faqs.length > 0 ? (
          <section className='mt-24'>
            <Edge
              location='top'
              direction='right'
              outerClassName='text-white'
              innerClassName='text-offWhite'
            />
            <div className='bg-offWhite px-12 lg:px-0'>
              <div className="text-center max-w-6xl mx-auto pb-12">
                <h2 className="c-hero__title inline-block mb-12 font-bold pb-2 mt-12">
                  FAQs
                </h2>
                <ul>
                  {faqs.map((faq) => {
                    return (
                      <li className='w-full bg-white mb-12 py-6 rounded-md shadow-lg'>
                        <h3 className='text-3xl font-bold py-4 pt-6 rounded-tl-md rounded-tr-md mb-2'>{faq.question.text}</h3>
                        <HtmlContent html={faq.answer.html} className='c-prose tracking-wide xleading-relaxed pb-6 px-12' />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </section>
        ) : <MissingCmsValueNotif missingKey="faq" />}
      </div>

      <Modal ref={modalElement}>
        {currentTeamMemberViewing ? (
          <div className='max-w-sm space-y-8 px-4 z-highest'>
          <h6 className='h3'>{currentTeamMemberViewing.team_member_name.text}</h6>
          <Image
            key={currentTeamMemberViewing.team_member_image.url}
            fallbackAlt={currentTeamMemberViewing.team_member_image.alt || 'Team member image'}
            dimensions={{ width: 64, height: 64 }}
            className="mb-4 object-cover"
            {...currentTeamMemberViewing.team_member_image}
          />
          <HtmlContent html={currentTeamMemberViewing.team_member_desc.html} className="sm:text-center md:text-left leading-relaxed" />
          <div className={`grid grid-cols-2 justify-items-center ${currentTeamMemberViewing.team_member_github.url || currentTeamMemberViewing.team_member_linkedin.url ? 'pb-12' : ''}`}>
              {
                currentTeamMemberViewing.team_member_github.url ? (
                  <a
                    href={currentTeamMemberViewing.team_member_github.url}
                    rel={'noopener noreferrer'}
                    target={'_blank'}
                  >
                    <Github className='w-8 h-8 fill-current' />
                  </a>
                ) : null
              }
              {
                currentTeamMemberViewing.team_member_linkedin.url ? (
                  <a
                    href={currentTeamMemberViewing.team_member_linkedin.url}
                    rel={'noopener noreferrer'}
                    target={'_blank'}
                  >
                    <Linkedin className='w-8 h-8 fill-current' />
                  </a>
                ) : null
              }
            </div>
        </div>
        ) : null}
      </Modal>
    </>
  );
}

ProjectPhaseView.propTypes = {
  data: PropTypes.object.isRequired
}

export default ProjectPhaseView
